<template>
	<div
		class="full-height flex-column bg-gray-light"
	>
		<div
			class="pa-10 full-height pb-30 overflow-y-auto "
		>
			<div class="">

				<h6>주문정보</h6>
				<div class="pa-10 box-shadow bg-white">
					<div
						class="justify-space-between font-weight-bold under-line-dashed pb-10"
					>
						<span>주문번호</span>
						<span>{{ item.order_number }}</span>
					</div>

					<div
						class="mt-10 justify-space-between"
					>
						<span class="font-weight-bold">주문일시</span>
						<span>{{ item.wDate }}</span>
					</div>
				</div>
			</div>

			<div class="mt-10">
				<h6>주문자 정보</h6>
				<div
					class="pa-10 bg-white box-shadow"
				>
					<div class=" justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">이름</span>
						<span>{{ item.member_name }}</span>
					</div>
					<div class="mt-5  justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">연락처</span>
						<span>{{ item.member_tell }}</span>
					</div>
				</div>
			</div>

			<div class="mt-10">
				<h6>수신자 정보</h6>
				<div
					class="pa-10 bg-white box-shadow"
				>
					<div class=" justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">이름</span>
						<span>{{ item.d_name }}</span>
					</div>
					<div class="mt-5  justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">연락처</span>
						<span>{{ item.d_tell }}</span>
					</div>
				</div>
			</div>

			<div class="mt-10">
				<h6>상품 정보</h6>
				<div></div>
			</div>

			<template
				v-if="item.supply_list.length > 0"
			>
				<ul class="">
					<li
						v-for="(supply, item_index) in item_list"
						:key="item_index"
						class="under-line mb-10  bg-white box-shadow"
					>
						<ul>
							<li
								v-for="(odt, product_index) in supply.odt_list"
								:key="'product_' + product_index"
								class="pa-10 under-line full-height justify-space-between"
							>
								<div class="flex-1 full-height pdt-img justify-center flex-column mr-10">
									<img
										v-if="odt.pdt_img"
										:src="odt.pdt_img"
									/>
									<v-icon
										v-else
									>mdi mdi-image</v-icon>
								</div>
								<div class="flex-3">
									<div class="under-line justify-space-between">
										{{ odt.pdt_name }}
									</div>
									<div
										class="under-line-dashed"
									>
										<div class="  color-gray">
											<span>선택옵션: </span>
											<span>{{ odt.op_name}}</span>
										</div>
										<div
											class=" justify-space-between"
										>
											<span
												class="flex-2 font-weight-bold"
											>{{ odt.pdt_price | makeComma }} 원</span>

											<span>수량: {{ odt.op_cnt }} 개</span>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div
							class="pa-10 justify-space-between under-line-dashed"
						>
							<span class="font-weight-bold">상품 합계</span>
							<span class="color-blue font-weight-bold">{{ supply.total_price | makeComma }} 원</span>
						</div>
						<div
							v-if="false"
							class="mt-10 prl-10 justify-space-between "
						>
							<span class="font-weight-bold">배송비 <span class="size-px-11">{{ supply.delivery_type_agency }}</span></span>
							<span class="font-weight-bold">{{ supply.delivery_price_agency | makeComma }} 원</span>
						</div>
						<div
							v-if="supply.island_delivery > 0"
							class="prl-10 mb-10 justify-space-between "
						>
							<span class="font-weight-bold">도서 산간 추가배송비</span>
							<span class="font-weight-bold">{{ supply.island_delivery | makeComma }} 원</span>
						</div>
					</li>
				</ul>
			</template>

			<div
				v-if="is_m && item.o_status == 2"
				class="mt-30"
			>
				<h6 class="color-primary">상품권 정보</h6>

				<table class="table  mt-10 box-shadow">
					<colgroup>
					</colgroup>
					<thead>
					<tr>
						<th>PIN</th>
						<th>비밀번호</th>
						<th>발급 상태</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in items_pin"
						:key="item.uid"
					>
						<td>{{ item.orgTranSeq ? '취소' : item.appNo }}</td>
						<td>{{ item.orgTranSeq ? '' : item.issCd }}</td>
						<td :class="{'color-red' : item.orgTranSeq }">{{ item.tranStatusName }}</td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:options="item_search"

					@click="getSearch"
				></Pagination>
			</div>

			<h6 class="mt-10">결제 정보</h6>
			<div
				class="pa-10 bg-white box-shadow font-weight-bold"
			>
				<div class=" justify-space-between under-line-dashed">
					<span class="font-weight-bold">결제금액</span>
					<span><span class="color-blue font-weight-bold">{{ item.order_price | makeComma }}</span> 원</span>
				</div>
				<div class=" mt-5  justify-space-between under-line-dashed">
					<span class="font-weight-bold">결제</span>
					<span>{{ pay_div }}</span>
				</div>
				<div class=" mt-5  justify-space-between under-line-dashed">
					<span class="">결제 상태</span>
					<span>{{ order_status }}</span>
				</div>
				<div class=" mt-5 text-right ">
					{{ item.pay_info }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
export default {
	name: 'OrderResult'
	, components: {Pagination}
	, props: ['Axios', 'codes', 'TOKEN']
	,data: function(){
		return {
			program: {
				name: '주문완료'
				,top: false
				,title: true
				,bottom: true
				,not_back: true
			}
			,item: {
				result: {

				}
				, supply_list: [

				]
				, order_number: this.order_num_new
			}
			, item_search: {
				page: 1
				, list_cnt: 10
				, order_number: this.$route.params.order_num_new
			}
			, items_pin: []
		}
	}
	,computed: {
		pay_div: function(){
			let div = ''
			if(this.item.pay_div == 'bank'){
				div = '무통장 입금'
			}else if(this.item.pay_div == 'card'){
				div = '카드 결제'
			}
			return div
		}
		,order_status: function(){
			let status = ''
			if(this.item.o_status == 1){
				status = '입금대기'
			}else{
				status = '결제완료'
			}
			return status
		}
		,item_list: function(){

			let self = this
			return this.item.supply_list.filter(function(item){

				return item.odt_list.filter(function(odt){

					odt.order_status_name = self.codes.odt_status[odt.order_status].name
					odt.order_status_color = self.codes.odt_status[odt.order_status].color
					odt.options = {}
					if(odt.pdt_img1){
						odt.pdt_img = self.codes.img_url + odt.pdt_img1
					}
					switch(odt.order_status){
						case 'step4':
							odt.is_step5 = true
							odt.is_step31 = true
							odt.is_step41 = true
							break;
						case 'step1':
							odt.is_step21 = true
							break;
					}

					return odt
				})
			})
		}

		, is_m: function(){
			let t = false
			this.item.supply_list.filter(function(item){
				item.odt_list.filter(function(odt){
					if(odt.pdt_div == 'mobile' || odt.pdt_div == 'cucudas'){
						t = true
						return
					}
				})
			})
			return t
		}
	}
	,methods:{
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'order/getOrderDetail'
					,data: {
						order_num_new: this.$route.params.order_num_new
					}
				})

				if(result.success){
					this.item = result.data
					await this.getPin()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toShipping: function(odt){
			this.$emit('toShipping', odt)
		}
		, getPin: async function(){
			if(this.item.o_status != 2){
				return false
			}
			this.$bus.$emit('on', true)
			try{
				const result = await this.Axios({
					method: 'get'
					,url: 'order/getOderPin'
					,data: this.item_search
					, version: 'v2'
				})

				if(result.success){
					this.items_pin = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			this.item_search.page = page
			this.getPin()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.pdt-img { width: 80px; height: auto; overflow: hidden}
</style>